'use client'

import { useState, useEffect } from 'react';
import { Checkbox } from 'semantic-ui-react';
// import { useTranslations } from 'next-intl';

export default function CookieBanner({ translations }) {
  const [showBanner, setShowBanner] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
//   const t = useTranslations('common');
  
  // Initialize with default values
  const [cookieSettings, setCookieSettings] = useState({
    necessary: true,
    analytics: false,
    marketing: false
  });

  useEffect(() => {
    try {
      const savedSettings = localStorage.getItem('cookieConsent');
      if (savedSettings) {
        const parsed = JSON.parse(savedSettings);
        setCookieSettings(parsed);
      } else {
        setShowBanner(true);
      }
    } catch (error) {
      console.error('Error loading cookie settings:', error);
      setShowBanner(true);
    }
  }, []);

  const handleAcceptAll = () => {
    setCookieSettings({
      necessary: true,
      analytics: true,
      marketing: true
    });
    saveCookieChoice({
      necessary: true,
      analytics: true,
      marketing: true
    });
  };

  const handleAcceptSelected = () => {
    const settings = {
      ...cookieSettings,
      necessary: true // ensure necessary is always true
    };
    saveCookieChoice(settings);
  };

  const saveCookieChoice = (settings) => {
    localStorage.setItem('cookieConsent', JSON.stringify(settings));
    setShowBanner(false);
    // Hier können die entsprechenden Cookies gesetzt/gelöscht werden
    if (settings.analytics) {
      // Analytics-Cookies setzen
    }
    if (settings.marketing) {
      // Marketing-Cookies setzen
    }
  };

  if (!showBanner) return null;

  return (
    <div className="cookie-banner">
      <div className="cookie-banner-container">
        {!showDetails ? (
          <div className="cookie-banner-main">
            <div className="cookie-banner-content">
              <h3 className="cookie-banner-title">{translations.cookie_title}</h3>
              <p className="cookie-banner-description">{translations.cookie_description}</p>
            </div>
            <div className="cookie-banner-buttons">
              <button
                onClick={() => setShowDetails(true)}
                className="btn btn-outline"
              >
                {translations.cookie_customize}
              </button>
              <button
                onClick={handleAcceptAll}
                className="btn btn-primary"
              >
                {translations.cookie_accept_all}
              </button>
            </div>
          </div>
        ) : (
          <div>
            <h3 className="cookie-banner-title">{translations.cookie_settings}</h3>
            <div className="cookie-settings">
              <div className="cookie-setting-item">
                <div className="cookie-setting-content">
                  <h4>{translations.cookie_necessary}</h4>
                  <p>{translations.cookie_necessary_description}</p>
                </div>
                <Checkbox
                  checked={cookieSettings.necessary}
                  disabled
                />
              </div>
              
              <div className="cookie-setting-item">
                <div className="cookie-setting-content">
                  <h4>{translations.cookie_analytics}</h4>
                  <p>{translations.cookie_analytics_description}</p>
                </div>
                <Checkbox
                  checked={cookieSettings?.analytics ?? false}
                  onChange={(e, data) => setCookieSettings(prev => ({
                    ...(prev || { necessary: true, analytics: false, marketing: false }),
                    analytics: data.checked
                  }))}
                />
              </div>
              
              <div className="cookie-setting-item">
                <div className="cookie-setting-content">
                  <h4>{translations.cookie_marketing}</h4>
                  <p>{translations.cookie_marketing_description}</p>
                </div>
                <Checkbox
                  checked={cookieSettings?.marketing ?? false}
                  onChange={(e, data) => setCookieSettings(prev => ({
                    ...(prev || { necessary: true, analytics: false, marketing: false }),
                    marketing: data.checked
                  }))}
                />
              </div>
            </div>
            
            <div className="cookie-banner-buttons">
              <button
                onClick={() => setShowDetails(false)}
                className="btn btn-outline"
              >
                {translations.back}
              </button>
              <button
                onClick={handleAcceptSelected}
                className="btn btn-primary"
              >
                {translations.cookie_save_settings}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}