import React, {useState, useEffect, useRef} from "react"
import { useNavigate, useParams } from "react-router-dom";
import { Button, Flag, Icon, Header, Form, Container, List, Segment, Message, Grid, GridColumn, Image, Label, ButtonGroup } from 'semantic-ui-react'
import { useTranslation } from "react-i18next"
import i18next from 'i18next'
import cookies from 'js-cookie'
import axios from "axios"

import MenuBar from "./MenuBar";
import ErrorPage from "./ErrorPage";
import BlankPage from "./BlankPage";
import GreetingIllustration from "./GreetingIllustration";

import ReCAPTCHA from "react-google-recaptcha";

import { BedrockRuntimeClient, InvokeModelCommand } from "@aws-sdk/client-bedrock-runtime";
import { ReplaceIamInstanceProfileAssociationCommand } from "@aws-sdk/client-ec2";

const BuyerInfo = () => {
    const { t } = useTranslation()
    const currentLanguageCode = cookies.get('i18next') || 'en'

    const [deviceMobile, setDeviceMobile] = useState(false);

    const [presentCategory, setPresentCategory] = useState("")
    const [code_valid, setCode_valid] = useState(false);
    const [code_checked, setCode_checked] = useState(false);
    const [checkedCAPTCHA, setCheckedCAPTCHA] = useState(false);
    const [hideMessage, setHideMessage] = useState(true);
    const [textCopied, setTextCopied] = useState(false);

    const captchaRef = useRef(null);

    const langOptions = [
        {
          key: 'English',
          text: 'EN',
          code: 'en',
          image: <Flag name='gb' />
        },
        {
          key: 'Deutsch',
          text: 'DE',
          code: 'de',
          image: <Flag name='de' />
        }
    ]

    const checkDevice = () => {
        if (window.innerWidth <= 950) {
          setDeviceMobile(true);
        } else {
          setDeviceMobile(false);
        }
    };
    
    useEffect(() => {
        checkDevice();
        window.addEventListener("resize", checkDevice);
        return () => window.removeEventListener("resize", checkDevice);
    }, []);

    const [activeItem, setActiveItem] = useState('')

    let navigate = useNavigate(); 
    const routeChange = (x) =>{ 
      let path = `${x}`; 
      navigate(path);
    }

    const checkPresentCode = async (pk) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_CHECK_CODE}/code/${pk}`);
            if (res.data.Item.validStatus === "valid") {
                setPresentCategory(res.data.Item.category)
                setCode_checked(true)
                setCode_valid(true)
            }
            else {
                setCode_valid(false)
                setCode_checked(true);
                console.log('This code does not exist or has already been used.');
            }
        }
        catch (err) {
            setCode_checked(true);
            setCode_valid(false)
            console.log(`An error has occurred: ${err}`);
        }
    }

    const updateNamesForPresentCode = async (pk, buyer_name, recipient_name) => {
        try {
            const params = {
                "PK": pk,
                "buyer_name": buyer_name,
                "recipient_name": recipient_name
            }
            await axios.patch(`${process.env.REACT_APP_CHECK_CODE}/code/${pk}`, params);
            console.log("buyer and recipient names updated")
        }
        catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }

    const checkHuman = () => {
        let token = captchaRef.current.getValue();
        if (token) {
            checkPresentCode(code)
            setCheckedCAPTCHA(true);
        } else {
            setHideMessage(false)
            setCheckedCAPTCHA(false)
        }
    }

    const generateText = async () => {
        const client = new BedrockRuntimeClient({
            region:"eu-central-1",
            credentials: {
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            },
        })

        //const prompt = `Schreibe einen Grußtext für einen Geschenkcode, mit dem der Beschenkte sich aus einer größeren Auswahl an Produkten sein Lieblingsgeschenk auswählen kann. Nenne dabei keine konkreten Geschenke. Name des Schenkers: ${buyer_name_input}, Name des Beschenkten: ${recipient_name}, Beziehung zwischen Beschenktem und Schenker: ${relation}, Anlass des Geschenks: ${occasion}, Zusatzinformationen: ${add_info}`;
        const prompt = t('buyer_prompt_text') + t('buyer_prompt_buyer_name') + buyer_name_input + t('buyer_prompt_recipient_name') + recipient_name + t('buyer_prompt_relation') + relation + t('buyer_prompt_occasion') + occasion + t('buyer_prompt_add_info') + add_info + t('buyer_prompt_link') + "https://presentolo.com/" + routeParams.code

        const input = {
        // You can change the modelId
        // "anthropic.claude-v1"
        // "anthropic.claude-instant-v1"
        // "anthropic.claude-v2"
        modelId: "anthropic.claude-3-5-sonnet-20240620-v1:0",
        contentType: "application/json",
        accept: "application/json",
        body: JSON.stringify({
            prompt: `\n\nHuman:${prompt}\n\nAssistant:`,
            max_tokens_to_sample: 300,
            temperature: 0.5,
            top_k: 250,
            top_p: 1,
            // hier muss vermutlich etwas geändert werden --> in Dokumentation nachschauen
        }),
        };

        // Create an InvokeModelCommand with the input parameters
        const command = new InvokeModelCommand(input);

        try {
            // Send the command to invoke the model and await the response
            const response = await client.send(command);
    
            // Decode the raw response
            const rawRes = response.body;
    
            // Convert the raw response to a JSON string
            const jsonString = new TextDecoder().decode(rawRes);
    
            // Parse the JSON string
            const parsedResponse = JSON.parse(jsonString);
    
            // Return the generated completion text
            return parsedResponse.completion;
        } catch (error) {
            console.error("Error generating text:", error);
            throw error;
        }
    }

    const webshare = async (text) => {
        try {
          await navigator.share({ text: text });
        } catch (e) {
          if (e.toString().includes('AbortError')) {
            console.log('Hello world aborted :(');
          }
        }
      }

    const handleShare = async (text, media) => {
        if (media === "whatsapp"){
            console.log("whatsapp")
            if (deviceMobile){
                const newWindow = window.open(`whatsapp://send?text=${text}`, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
            } else {
                const newWindow = window.open(`https://web.whatsapp.com/send?text=${text}`, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
            }
        }
        if (media === "webshare_api"){
            console.log("webshare api")
            webshare(text)
        }
        if (media === "email"){
            console.log("email")

            // Construct mailto link with parameters
            const mailtoLink = `mailto: ?subject=${t('buyer_email_subject')} &body=${text}`;
            window.location.href = mailtoLink;
        }
    };

    const showShareButtons = () => {
        if (navigator.share){
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)){
                return(
                    <div className="Container">
                        <ButtonGroup vertical={deviceMobile ? true : false} >
                            <Button icon labelPosition='left' compact secondary={textCopied ? false : true} size='big' onClick={copyText}>
                                <Icon name={textCopied ? "clipboard check" : "clipboard"} />
                                {textCopied ? t('copied') : t('copy')}
                            </Button>
                            <Button icon labelPosition='left' compact color="grey" size="big" onClick={() => handleShare(text, "webshare_api")}>
                                <Icon name="share square" />
                                {t('buyer_share_button')}
                            </Button>
                        </ButtonGroup>
                    </div>
                )
            }
            else {
                return(
                    <div className="Container">
                        <ButtonGroup vertical={deviceMobile ? true : false} >
                            <Button icon labelPosition='left' compact secondary={textCopied ? false : true} size='big' onClick={copyText}>
                                <Icon name={textCopied ? "clipboard check" : "clipboard"} />
                                {textCopied ? t('copied') : t('copy')}
                            </Button>
                            <Button icon labelPosition='left' compact color="grey" size="big" onClick={() => handleShare(text, "webshare_api")}>
                                <Icon name="share square" />
                                {t('buyer_share_button')}
                            </Button>
                            <Button compact icon labelPosition='left' color="green" size="big" onClick={() => handleShare(text, "whatsapp")}>
                                <Icon name="whatsapp" />
                                WhatsApp
                            </Button>
                        </ButtonGroup>
                    </div>
                )
            }
        }
        else {
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)){
                return(
                    <div className="Container">
                        <ButtonGroup vertical={deviceMobile ? true : false} >
                            <Button icon labelPosition='left' compact secondary={textCopied ? false : true} size='big' onClick={copyText}>
                                <Icon name={textCopied ? "clipboard check" : "clipboard"} />
                                {textCopied ? t('copied') : t('copy')}
                            </Button>
                            <Button compact icon labelPosition='left' color="grey" size="big" onClick={() => handleShare(text, "email")}>
                                <Icon name="mail square" />
                                E-Mail
                            </Button>
                        </ButtonGroup>
                    </div>
                )
            }
            else {
                return(
                    <div className="Container">
                        <ButtonGroup vertical={deviceMobile ? true : false} >
                            <Button circular icon labelPosition='left' compact secondary={textCopied ? false : true} size='big' onClick={copyText}>
                                <Icon name={textCopied ? "clipboard check" : "clipboard"} />
                                {textCopied ? t('copied') : t('copy')}
                            </Button>
                            <Button circular compact icon labelPosition='left' color="grey" size="big" onClick={() => handleShare(text, "email")}>
                                <Icon name="mail square" />
                                    E-Mail
                            </Button>
                            <Button circular compact icon labelPosition='left' color="green" size="big" onClick={() => handleShare(text, "whatsapp")}>
                                <Icon name="whatsapp" />
                                    WhatsApp
                            </Button>
                        </ButtonGroup>
                    </div>
                )
            }
        }
    }

    const textAreaRef = React.createRef();

    const copyText = () => {
        // Verwenden Sie die Clipboard API, um den Text zu kopieren
        navigator.clipboard.writeText(text);
    
        // Ändere den Text der Button-Beschriftung für einen kurzen Moment
        setTextCopied(true)
    
        // Setze den Text nach einer gewissen Zeit wieder zurück
        setTimeout(() => {
            setTextCopied(false);
        }, 1000); // Hier können Sie die Dauer in Millisekunden anpassen
    };

    const handleGenerateTextButtonClick = async () => {
        try {
          setLoading(true); // Setze loading auf true beim Button-Click
          //setText(await generateText());
          const generatedText = await generateText();
          const modifiedText = generatedText.substring(1); // Entferne die erste Stelle des generierten Textes
          setText(modifiedText);
        } finally {
          setLoading(false); // Setze loading auf false nachdem die Textgenerierung abgeschlossen ist
        }
    };

    const relationship_options = [
        { key: 1, text: t('buyer_friend'), value: t('buyer_friend') },
        { key: 2, text: t('buyer_together'), value: t('buyer_together') },
        { key: 3, text: t('buyer_related'), value: t('buyer_related') },
        { key: 4, text: t('buyer_work'), value: t('buyer_work') },
        { key: 5, text: t('buyer_else'), value: t('buyer_else') },
    ]

    const occasion_options = [
        { key: 'English', text: 'English', value: 'English' },
        { key: 'French', text: 'French', value: 'French' },
        { key: 'Spanish', text: 'Spanish', value: 'Spanish' },
        { key: 'German', text: 'German', value: 'German' },
        { key: 'Chinese', text: 'Chinese', value: 'Chinese' },
    ]

    const routeParams = useParams()
    const code = routeParams.code.slice(1, -1)
    const buyer_name = routeParams.name

    const [buyer_name_input, setBuyer_name] = useState(buyer_name)
    const [recipient_name, setRecipient_name] = useState("")
    const [occasion, setOccasion] = useState("")
    const [relation, setRelation] = useState("")
    const [add_info, setAddInfo] = useState("")
    const [text, setText] = useState("")

    const [loading, setLoading] = useState(false)
    const [continueClicked, setContinueClicked] = useState(false)

    if (checkedCAPTCHA === false){
        return (
            <>
                <div className="App">

                    <MenuBar />

                    <div className="Container">
                        <Header size='large'>{t('RECAPTCHA')}</Header>

                        <br /><br />

                        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />
                        
                        <br />

                        <Message hidden={hideMessage} error content={t('RECAPTCHA_error')} />

                        <br />

                        <Button circular color="orange" size="huge" onClick={() => checkHuman()}>
                            {t('verify')}
                        </Button>
                    </div>
                    
                    <br /><br /><br /><br />
                    {/*Footer*/}

                    <div className="Container_bottom">
                        <List centered bulleted horizontal link size='large'>
                            <List.Item as='a' onClick={() => navigate('/terms')}>
                            {t('terms')}
                            </List.Item>
                            <List.Item as='a' onClick={() => navigate('/data_protection')}>
                            {t('data_protection')}
                            </List.Item>
                            <List.Item as='a' onClick={() => navigate('/imprint')}>
                            {t('imprint')}
                            </List.Item>
                        </List>
                        <div className="Container_middle">
                            <Button target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/presentolo" circular color="linkedin" icon="linkedin" />
                        </div>
                    </div>
                </div>
            </>
        )
    }
    
    if (checkedCAPTCHA === true && code_valid === false && code_checked === false) {return (
        <BlankPage />
    )}
    
    if (checkedCAPTCHA === true && code_valid === false && code_checked === true) {return (
        <ErrorPage />
    )}

    if (checkedCAPTCHA === true && code_valid === true && code_checked === true && continueClicked === false) {return (
        <>
        <div className="App">

            <MenuBar />

            <div className="Container">
                <Container text>
                    <Header size='large'><Icon color='green' name="check circle" /> {t('buyer_welcome_presentolo')}{buyer_name === "" ? "" : ", " + buyer_name}!</Header>
                    {/* <br />
                    <Image src={require("./assets/order_illustration.png")} size='large' centered /> */}
                    <br />
                    <p>{t('buyer_congratulations')}</p>
                    <p>{t('buyer_give_generator_information')}</p>
                    <p>{t('buyer_have_fun')} <Icon color='red' name="heart" /></p>
                </Container>
            </div>

            <div className="Container">
                <Label basic size='large' color='orange'>{t('your_code')} <b>{code}</b></Label>
            </div>

            <div className="Container_small_middle">
                <Button icon labelPosition="left" size="huge" secondary={textCopied ? false : true} compact onClick={ () => {
                    navigator.clipboard.writeText("https://presentolo.com/%22"+ code + "%22")
                
                    setTextCopied(true)
                
                    setTimeout(() => {
                        setTextCopied(false);
                    }, 1000);
                }}>
                    <Icon name={textCopied ? "clipboard check" : "clipboard"} />
                    {t('copy_code')}
                </Button>
                <Button icon labelPosition="right" size="huge" color="orange" compact onClick={() => setContinueClicked(true)}>
                    <Icon name="arrow right" />
                    {t('buyer_generate_button')}
                </Button>
            </div>
        </div>

        {/*Footer*/}

        <div className="Container">
            <List centered bulleted horizontal link size='large'>
                <List.Item as='a' onClick={() => navigate('/terms')}>
                {t('terms')}
                </List.Item>
                <List.Item as='a' onClick={() => navigate('/data_protection')}>
                {t('data_protection')}
                </List.Item>
                <List.Item as='a' onClick={() => navigate('/imprint')}>
                {t('imprint')}
                </List.Item>
            </List>
            <div className="Container_middle">
                <Button target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/presentolo" circular color="linkedin" icon="linkedin" />
            </div>
        </div>
    </>
    )}

    if (continueClicked === true) {return(
        <>
        <div className="App">

            <MenuBar />

            <Segment raised size='massive'>
            <Label attached={deviceMobile ? 'top' : 'top left'} basic size='large' color='orange'>{t('your_code')} <b>{code}</b></Label>
                <Grid columns={2} relaxed='very' stackable divided centered stretched>
                <Grid.Column>
                    <div className="form-container">
                    <Form size="big">
                        <Form.Group widths='equal'>
                            <Form.Input
                                icon='user'
                                iconPosition='left'
                                label={t('buyer_your_name')}
                                placeholder='Daniel'
                                value={buyer_name_input}
                                onChange={(e) => setBuyer_name(e.target.value)}
                            />
                            <Form.Input
                                icon='address book outline'
                                iconPosition='left'
                                label={t('buyer_recipient')}
                                placeholder='Lia'
                                value={recipient_name}
                                onChange={(e) => setRecipient_name(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input
                                icon='birthday cake'
                                iconPosition='left'
                                label={t('buyer_occasion')}
                                placeholder={t('weihnachten')}
                                value={occasion}
                                onChange={(e) => setOccasion(e.target.value)}
                            />
                            {/*'<Form.Field
                                control={Select}
                                label={t('buyer_relationship')}
                                options={options}
                               onChange={(e, { value })  => setRelation(value)}
                            />*/}
                            <Form.Input
                                icon='handshake'
                                iconPosition='left'
                                label={t('buyer_relationship')}
                                placeholder={t('buyer_work')}
                                value={relation}
                                onChange={(e) => setRelation(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Input
                            placeholder={t('buyer_additional_information_placeholder')}
                            icon='plus square outline'
                            iconPosition='left'
                            label={t('buyer_additional_information')}
                            value={add_info}
                            onChange={(e) => setAddInfo(e.target.value)}
                        />
                    </Form>
                    <br />
                    <div className="Container_small_middle">
                        <Button icon compact size="big" onClick={() => setContinueClicked(false)}>
                            <Icon name="arrow left" />
                        </Button>
                        <Button compact color="orange" size="big" icon labelPosition='right' onClick={async () => {
                            handleGenerateTextButtonClick()
                            updateNamesForPresentCode(code, buyer_name_input, recipient_name)
                        }}>
                            {t('buyer_generate_button')}
                            <Icon name='play' />
                        </Button>
                    </div>
                    </div>
                </Grid.Column>

                <Grid.Column verticalAlign='middle'>
                    <Form loading={loading} size='big'>
                        <Form.TextArea ref={textAreaRef} value={text} onChange={(e) => setText(e.target.value)} rows={10}/>
                    </Form>
                    <br />
                    {showShareButtons()}
                </Grid.Column>
                </Grid>
            </Segment>

            {/*Footer*/}

            <div className="Container">
                <List centered bulleted horizontal link size='large'>
                    <List.Item as='a' onClick={() => navigate('/terms')}>
                    {t('terms')}
                    </List.Item>
                    <List.Item as='a' onClick={() => navigate('/data_protection')}>
                    {t('data_protection')}
                    </List.Item>
                    <List.Item as='a' onClick={() => navigate('/imprint')}>
                    {t('imprint')}
                    </List.Item>
                </List>
                <div className="Container_middle">
                    <Button target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/presentolo" circular color="linkedin" icon="linkedin" />
                </div>
            </div>
            </div>
        </>
    )}
}

export default BuyerInfo;